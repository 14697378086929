.about {
  margin: 70px auto 40px;
  padding: 8px;
  width: 260px;
  font: 10px/18px 'Lucida Grande', Arial, sans-serif;
  color: #bbb;
  text-align: center;
  text-shadow: 0 -1px rgba(black, .3);
  background: #383838;
  background: rgba(#222, .8);
  border-radius: 4px;
  @include linear-gradient(top, rgba(black, 0), rgba(black, .3));
  @include box-shadow(inset 0 0 0 1px rgba(black, .2), 0 0 6px rgba(black, .4));

  a {
    color: #eee;
    text-decoration: none;
    border-radius: 2px;
    @include transition(background .1s);

    &:hover {
      text-decoration: none;
      background: #555;
      background: rgba(white, .15);
    }
  }
}

.about-links {
  height: 30px;

  > a {
    float: left;
    width: 50%;
    line-height: 30px;
    font-size: 12px;
  }
}

.about-author {
  margin-top: 5px;

  > a {
    padding: 1px 3px;
    margin: 0 -1px;
  }
}
