@font-face {
    font-family: 'Special Elite';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/special-elite/special-elite-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Special Elite Regular'), local('SpecialElite-Regular'),
    url('/fonts/special-elite/special-elite-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/special-elite/special-elite-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/special-elite/special-elite-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/special-elite/special-elite-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/special-elite/special-elite-v10-latin-regular.svg#SpecialElite') format('svg'); /* Legacy iOS */
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html, button, input, select, textarea,
.pure-g [class *= "pure-u"] {
    font-family: Helvetica, Arial, sans-serif;
    letter-spacing: 0.01em;
}
p {
    font-family: "Special Elite", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* --------------------------
 * Element Styles
 * --------------------------
*/

body {
    min-width: 320px;
    background-color: #fff;
    color: #777;
    line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    color: rgb(75, 75, 75);
}
h3 {
    font-size: 1.25em;
}
h4 {
    font-size: 1.125em;
}

a {
    color: #3b8bba; /* block-background-text-normal */
    text-decoration: none;
}

a:visited {
    color: #004085; /* block-normal-text-normal */
}

dt {
    font-weight: bold;
}
dd {
    margin: 0 0 10px 0;
}

aside {
    background: #1f8dd6; /* same color as selected state on site menu */
    margin: 1em 0;
    padding: 0.3em 1em;
    border-radius: 3px;
    color: #fff;
}
aside a, aside a:visited {
    color: rgb(169, 226, 255);
}


/* --------------------------
 * Layout Styles
 * --------------------------
*/

/* Navigation Push Styles */
#layout {
    position: relative;
    padding-left: 0;
}
#layout.active #menu {
    left: 160px;
    width: 160px;
}

/* Apply the .box class on the immediate parent of any grid element (pure.css-u-*) to apply some padding. */
.l-box {
    padding: 1em;
}

.l-wrap {
    margin-left: auto;
    margin-right: auto;
}
.content .l-wrap {
    margin-left: -1em;
    margin-right: -1em;
}


/* --------------------------
 * Header Module Styles
 * -------------------------
*/

.header  {
    font-family: "Special Elite", "Helvetica Neue", Helvetica, Arial, sans-serif;
    max-width: 768px;
    margin: 0 auto;
    padding: 1em;
    text-align: center;
    border-bottom: 1px solid #eee;
    background: #fff;
    letter-spacing: 0.05em;
}
.header h1 {
    font-size: 300%;
    font-weight: 100;
    margin: 0;
}
.header h2 {
    font-size: 125%;
    font-weight: 100;
    line-height: 1.5;
    margin: 0;
    color: #666;
    letter-spacing: -0.02em;
}


/* --------------------------
 * Content Module Styles
 * --------------------------
*/

/* The content div is placed as a wrapper around all the docs */
.content {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 768px;
}

.content .content-subhead {
    margin: 2em 0 1em 0;
    font-weight: 300;
    color: #888;
    position: relative;
}

.content .content-spaced {
    line-height: 1.8;
}

.content .content-quote {
    font-family: "Special Elite","Georgia", serif;
    color: #666;
    font-style: italic;
    line-height: 1.8;
    border-left: 5px solid #ddd;
    padding-left: 1.5em;
    font-size: 1.25em;
}

.content-link {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: 100%;
    width: 20px;
    background: transparent url('/img/link-icon.png') no-repeat center center;
    background-size: 20px 20px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    .content-link {
        background-image: url('/img/link-icon@2x.png');
    }
}


/* --------------------------
 * Code Styles
 * --------------------------
*/

pre,
code {
    font-family: Consolas, Courier, monospace;
    color: #333;
    background: rgb(250, 250, 250);
}

code {
    padding: 0.2em 0.4em;
    white-space: nowrap;
}
.content p code {
    font-size: 90%;
}

.code {
    margin-left: -1em;
    margin-right: -1em;
    padding: 1em;
    border: 1px solid #eee;
    border-left-width: 0;
    border-right-width: 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.code code {
    font-size: 95%;
    white-space: pre;
    word-wrap: normal;
    padding: 0;
    background: none;
}
.code-wrap code {
    white-space: pre-wrap;
    word-wrap: break-word;
}

/* --------------------------
 * Footer Module Styles
 * --------------------------
*/

.footer {
    font-size: 87.5%;
    border-top: 1px solid #eee;
    margin-top: 3.4286em;
    padding: 1.1429em;
    background:  lightgrey;
}

.legal {
    line-height: 1.6;
    text-align: center;
    margin: 0 auto;
    padding: 3em;

}

.legal-license {
    margin-top: 0;

}
.legal-links {
    list-style: none;
    margin-bottom: 0;
}
.legal-copyright {
    margin-top: 0;
    margin-bottom: 0;
}

/* --------------------------
 * Main Navigation Bar Styles
 * --------------------------
*/

/* Add transition to containers so they can push in and out */
#layout,
#menu,
.menu-link {
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

#layout.active .menu-link {
    left: 160px;
}

#menu {
    margin-left: -160px; /* "#menu" width */
    width: 160px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000; /* so the menu or its navicon stays above all content */
    background: #191818;
   overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
#menu a {
    color: #999;
    border: none;
    white-space: normal;
    padding: 0.625em 1em;
}

#menu .pure-menu-open {
    background: transparent;
    border: 0;
}

#menu .pure-menu ul {
    border: none;
    background: transparent;

}

#menu .menu-item-divided {
    border-top: 1px solid #333;
}

#menu .pure-menu li a:hover,
#menu .pure-menu li a:focus {
    background: #333;
}

.menu-link {
    position: fixed;
    display: block; /* show this only on small screens */
    top: 0;
    left: 0; /* "#menu width" */
    background: #000;
    background: rgba(0,0,0,0.7);
    font-size: 0.65em; /* change this value to increase/decrease button size */
    z-index: 10;
    width: 4em;
    height: 4em;
    padding: 1em;

}

.menu-link:hover,
.menu-link:focus {
    background: #000;
}

.menu-link span {
    position: relative;
    display: block;
    margin-top: 0.9em;
}

.menu-link span,
.menu-link span:before,
.menu-link span:after {
    background-color: #fff;
    width: 100%;
    height: .2em;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
}

.menu-link span:before,
.menu-link span:after {
    position: absolute;
    top: -.55em;
    content: " ";
}

.menu-link span:after {
    top: .55em;
}

.menu-link.active span {
    background: transparent;
}

.menu-link.active span:before {
    -webkit-transform: rotate(45deg) translate(.5em, .4em);
    -moz-transform: rotate(45deg) translate(.5em, .4em);
    -ms-transform: rotate(45deg) translate(.5em, .4em);
    -o-transform: rotate(45deg) translate(.5em, .4em);
    transform: rotate(45deg) translate(.5em, .4em);
}

.menu-link.active span:after {
    -webkit-transform: rotate(-45deg) translate(.4em, -.3em);
    -moz-transform: rotate(-45deg) translate(.4em, -.3em);
    -ms-transform: rotate(-45deg) translate(.4em, -.3em);
    -o-transform: rotate(-45deg) translate(.4em, -.3em);
    transform: rotate(-45deg) translate(.4em, -.3em);
}

#menu .pure-menu-heading {
    font-size: 125%;
    font-weight: 300;
    letter-spacing: 0.1em;
    color: #fff;
    margin-top: 0;
    padding: 0.5em 0.8em;
    text-transform: uppercase;
}
#menu .pure-menu-heading:hover,
#menu .pure-menu-heading:focus {
    color: #999;
}

#menu .pure-menu-selected {
    background: #1f8dd6;
}

#out .pure-menu-selected a,
#menu .pure-menu-selected a {
    color: #fff;
}
.dropdown-menu  a{
    color: #999 !important;
}

.dropdown-item.active, .dropdown-item:active{
    color: #fff !important;
    background-color: #1f8dd6 !important;
}
/*
#menu li.pure-menu-selected a:hover,
#menu li.pure-menu-selected a:focus {
    background: none;
}

*/

/* ---------------------
 * Smaller Module Styles
 * ---------------------
*/

.pure-img-responsive {
    max-width: 100%;
    height: auto;
}

.pure-paginator .pure-button {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.pure-button {
    font-family: inherit;
}
a.pure-button-primary {
    color: white;
}


/* green call to action button class */
.notice {
    background-color: #61B842;
    color: white;
}

.muted {
    color: #ccc;
}



/* -------------
 * Table Styles
 * -------------
*/

.pure-table th,
.pure-table td {
    padding: 0.5em 1em;
}

.table-responsive {
    margin-left: -1em;
    margin-right: -1em;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 1em;
}
.table-responsive table {
    width: 100%;
    min-width: 35.5em;
    border-left-width: 0;
    border-right-width: 0;
}

.table-responsive .mq-table {
    width: 100%;
    min-width: 44em;
}
.mq-table th.highlight {
    background-color: rgb(255, 234, 133);
}
.mq-table td.highlight {
    background-color: rgb(255, 250, 229);
}
.mq-table th.highlight code,
.mq-table td.highlight code {
    background: rgb(255, 255, 243);
}
.mq-table-mq code {
    font-size: 0.875em;
}

/* ----------------------------
 * Example for full-width Grids
 * ----------------------------
*/

.grids-example {
    background: rgb(250, 250, 250);
    margin: 2em auto;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

/* --------------------------
 * State Rules
 * --------------------------
*/


.is-code-full {
    text-align: center;
}
.is-code-full .code {
    margin-left: auto;
    margin-right: auto;
}
.is-code-full code {
    display: inline-block;
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
}


/* --------------------------
 * Responsive Styles
 * --------------------------
*/

@media screen and (min-width: 35.5em) {

    .legal-license {
        text-align: left;
        margin: 0;
    }
    .legal-copyright,
    .legal-links,
    .legal-links li {
        text-align: left;
        margin: 0;
    }

}

@media screen and (min-width: 48em) {


    .l-wrap,
    .l-wrap .content {
        padding-left: 1em;
        padding-right: 1em;
    }
    .content .l-wrap {
        margin-left: -2em;
        margin-right: -2em;
    }

    .header,
    .content {
        padding-left: 2em;
        padding-right: 2em;
    }

    .header h1 {
        font-size: 320%;
    }
    .header h2 {
        font-size: 128%;
    }

    .content p {
        font-size: 1.125em;
    }

    .code {
        margin-left: auto;
        margin-right: auto;
        border-left-width: 1px;
        border-right-width: 1px;
    }

    .table-responsive {
        margin-left: auto;
        margin-right: auto;
    }
    .table-responsive table {
        border-left-width: 1px;
        border-right-width: 1px;
    }

}

@media (max-width: 58em) {
    /* Only apply this when the window is smaller. Otherwise, the following
    case results in extra padding on the left:
        * Make the window small. (Rotate to portrait on a mobile.)
        * Tap the menu to trigger the active state.
        * Make the window large again. (Rotate to landscape on mobile.)
    */
    #layout.active {
        position: relative;
        left: 160px;
    }

    nav.sticky a.first {
        padding-left: 55px;
    }
    .stickytab a.firsttab {
        margin-left: 25px;
    }

}

@media (min-width: 58em) {

    #layout {
        padding-left: 160px; /* left col width "#menu" */
        left: 0;
    }
    #menu {
        left: 160px;
    }
    .menu-link {
        position: fixed;
        left: 160px;
        display: none;
    }
    #layout.active .menu-link {
        left: 160px;
    }

}

/*DAOL */
nav.sticky {
    position: fixed; /*older browsers */
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: white;
    width: 100%
}

.stickytab {
    position: fixed; /*older browsers */
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: white;
    width: 100%
}


/* Terminal Styles */
.text-editor-wrap {
    display: block;
    margin: auto;
    max-width: 530px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 20px 70px;
    clear: both;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out; }

.title-bar {
    padding: 5px 0;
    font-family: "Lucida Grande", sans-serif;
    font-size: 0.75em;
    text-align: center;
    text-shadow: rgba(255, 255, 255, 0.8) 0px 1px 0px;
    background-color: #f8f8f8;
    background-image: -webkit-linear-gradient(top, #e8e8e8, #bcbbbc);
    background-image: -moz-linear-gradient(top, #e8e8e8, #bcbbbc);
    background-image: linear-gradient(top, #e8e8e8, #bcbbbc);
    box-shadow: inset rgba(255, 255, 255, 0.7) 0px 1px 1px;
    border-bottom: #6a6a6a 1px solid; }

.text-body {
    min-height: 150px;
    background-color: rgba(0, 0, 0, 0.85);
    padding: 10px;
    color: #f0f0f0;
    text-shadow: #000 0px 1px 0px;
    font-family: "Consolas", "Courier New", "Courier";
    font-size: 1.45em;
    line-height: 1.40em;
    font-weight: 500;
    text-align: left;
    overflow: hidden;
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out; }

/* Other */
.cap {
    font-size: 250%;
    vertical-align: bottom;
    line-height: .9em;
    color: #1f8dd6;
}

.jumper {
    padding: 0rem 1.5rem !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #1f8dd6 !important;
    color: white !important;
}
#subItems {
    z-index: 9;
}
figure, blockquote {
    margin: 5px ;

}

.list-group-sw.active {
    background-color: #1f8dd6 !important;
}

.gap {border: none; border-bottom: dashed 1px; width: 200px}

.glyphicon-remove::before {
    content: "\2717";
}
.glyphicon-ok::before {
    content: "\2713";
}

.voctab td {
 padding: 10px;
}

