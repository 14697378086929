@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

/*
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
*/

@import "node_modules/bootstrap/scss/dropdown";
/*
@import "button-group";
@import "input-group";
@import "custom-forms";
*/

@import "node_modules/bootstrap/scss/nav";
//@import "node_modules/bootstrap/scss/navbar";

/*
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
*/
@import "node_modules/bootstrap/scss/list-group";
/*
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
*/

//@import "node_modules/bootstrap/scss/utilities";
/*
@import "print";
*/